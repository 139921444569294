<template>
  <div class="guide-form-comp">
    <el-form
      :model="ruleForm"
      :rules="rules"
      label-position="top"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="内容记录"
            prop="contentRecord"
            class="auto-height-item"
          >
            <el-input
              type="textarea"
              :rows="5"
              maxlength="250"
              placeholder="请描述具体内容"
              v-model="ruleForm.contentRecord"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="项目选择" prop="projectId">
            <el-select
              style="width: 100%"
              v-model="ruleForm.projectId"
              @change="subsetCompany"
            >
              <el-option
                v-for="(item, index) in projectTypeList"
                :value="item.projectId"
                :label="item.projectName"
                :key="index"
                @click.native="optionUnit(item.projectName)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="公司选择" prop="enterpriseId">
            <el-select
              style="width: 100%"
              v-model="ruleForm.enterpriseId"
              filterable
              placeholder="请选择公司"
            >
              <el-option
                v-for="item in companyList"
                :key="item.enterpriseId"
                :value="item.enterpriseId"
                :label="item.enterpriseName"
                @click.native="changeCompany(item.enterpriseName)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="房号/单位名称" prop="positionId">
            <el-cascader
              @change="changePosition"
              style="width: 100%"
              placeholder="请选择房号/单位"
              filterable
              :options="positionDescNameList"
              v-model="ruleForm.positionId"
              :props="{
                checkStrictly: true,
                value: 'positionId',
                label: 'descName',
              }"
            >
            </el-cascader>
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="联系人" prop="contacts">
            <el-input
              maxlength="50"
              placeholder="请输入联系人"
              v-model="ruleForm.contacts"
            ></el-input
          ></el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话" prop="telephone">
            <el-input
              maxlength="50"
              placeholder="请输入联系电话"
              v-model="ruleForm.telephone"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="经办客服" prop="handledPeople">
            <el-input
              maxlength="50"
              placeholder="请输入经办客服"
              v-model="ruleForm.handledPeople"
            ></el-input
          ></el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="经办客服电话" prop="hotlineCusPhone">
            <el-input
              maxlength="50"
              placeholder="请输入经办客服电话"
              v-model="ruleForm.hotlineCusPhone"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="问题类型" prop="questionType">
            <el-select style="width: 100%" v-model="ruleForm.questionType">
              <el-option
                v-for="(item, index) in questionTypeList"
                :value="item.value"
                :label="item.questionName"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="什么类型事件？" prop="orderType">
            <el-radio-group
              v-model="ruleForm.orderType"
              size="medium"
              @change="changeOrderType"
            >
              <el-radio border label="N">非工单事件</el-radio>
              <el-radio border label="Y">工单事件</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="ruleForm.orderType === 'Y'">
        <el-col :span="12">
          <el-form-item label="工单模板" prop="orderTemplateId">
            <el-select style="width: 100%" v-model="ruleForm.orderTemplateId">
              <el-option
                v-for="(item, index) in workOrderTemplate"
                :value="item.orderConfigId"
                :label="item.configName"
                :key="index"
                @click.native="getConfigName(item.configName)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item align="right">
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            v-preventReClick
            >确定</el-button
          >
          <el-button @click="resetForm">取消</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {
  getProjectList,
  getSubsetPosition,
  getOrderTemplate,
  addWorkOrder,
  getCompany,
} from "@/api/ruge/gsPark/customerService/workOrderEvent";
import { listToTree } from "@/utils/tree";
import { async } from "@antv/x6/lib/registry/marker/async";
export default {
  data() {
    var validatorPhone = function (rule, value, callback) {
      if (value === "" || value === null) {
        callback(new Error("请填写联系电话"));
      } else if (!/^[0-9]+$/.test(value)) {
        //仅限制是数字即可，该字段可能为座机，联系电话字数不作限制
        callback(new Error("联系电话格式错误"));
      } else {
        callback();
      }
    };

    var valiCusPhone = function (rule, value, callback) {
      if (value === "" || value === null) {
        callback(new Error("请填写客服电话"));
      } else if (
        //0830-0830格式或0830-0830-0830或正常数字电话即可通过
        /^[0-9]+(-[0-9]+)?$/.test(value) ||
        /^[0-9]+(-[0-9]+)+(-[0-9]+)?$/.test(value)
      ) {
        callback();
      } else {
        callback(new Error("客服电话格式错误"));
      }
    };
    return {
      questionTypeList: [
        { questionName: "报事报修", value: "报事报修" },
        { questionName: "事项咨询", value: "事项咨询" },
        { questionName: "投诉建议", value: "投诉建议" },
        { questionName: "计费收费", value: "计费收费" },
        { questionName: "招商类", value: "招商类" },
      ],
      projectTypeList: [],
      companyList: [],
      positionDescNameList: [],
      workOrderTemplate: [],
      ruleForm: {
        contentRecord: null,
        projectName: null,
        projectId: null,
        enterpriseId: null,
        enterpriseName: null,
        positionName: null,
        positionId: null,
        contacts: null,
        telephone: null,
        handledPeople: null,
        hotlineCusPhone: null,
        questionType: null,
        orderType: null,
        orderTemplateId: null,
        orderTemplateName: null,
      },
      rules: {
        contentRecord: [
          { required: true, message: "内容记录为必填项", trigger: "blur" },
        ],
        projectId: [
          { required: true, message: "项目选择为必填", trigger: "change" },
        ],
        enterpriseId: [
          { required: true, message: "公司为必填", trigger: "change" },
        ],
        positionId: [
          { required: true, message: "房间/单位为必填", trigger: "change" },
        ],
        contacts: [
          { required: true, message: "联系人为必填", trigger: "blur" },
        ],
        telephone: [
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
        handledPeople: [
          { required: true, message: "经办人为必填", trigger: "blur" },
        ],
        hotlineCusPhone: [
          { required: true, validator: valiCusPhone, trigger: "blur" },
        ],
        questionType: [
          { required: true, message: "问题类型为必填项", trigger: "change" },
        ],
        orderType: [
          { required: true, message: "类型事件为必选项", trigger: "change" },
        ],
        orderTemplateId: [
          { required: true, message: "工单模板为必填项", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getProject();
  },
  methods: {
    //获取工单事件下的工单模板
    changeOrderType() {
      if (this.ruleForm.orderType == "Y") {
        getOrderTemplate({ projectId: this.ruleForm.projectId }).then((res) => {
          if (res) {
            this.workOrderTemplate = res.data;
          }
        });
      } else {
        this.ruleForm.orderTemplateId = null;
      }
    },
    getConfigName(data) {
      this.ruleForm.orderTemplateName = data;
    },
    optionUnit(data) {
      this.ruleForm.projectName = data;
    },
    //选择公司
    changeCompany(data) {
      console.log("data", data);
      this.ruleForm.enterpriseName = data;
    },
    getProject() {
      getProjectList().then((res) => {
        if (res) {
          this.projectTypeList = res;
        }
      });
    },
    //获取位置列表
    subsetPosition(id) {
      getSubsetPosition({
        projectId: id,
      }).then((res) => {
        if (res) {
          this.positionDescNameList = res;
        }
      });
      this.changeOrderType();
    },
    //获取公司列表
    subsetCompany(id) {
      getCompany({
        projectId: id,
      }).then((res) => {
        if (res) {
          this.ruleForm.enterpriseId = null;
          this.ruleForm.enterpriseName = null;
          this.companyList = res;
        }
      });
      this.changeOrderType();
    },
    changePosition(idList) {
      let idIndex = idList.length;
      this.ruleForm.positionId = idList[idIndex - 1];
      let data = listToTree(
        this.positionDescNameList,
        "positionId",
        "parentId"
      );
      this.recursivePosition(data);
    },
    recursivePosition(row) {
      let data = row.map((v) => {
        v.label = v.descName;
        v.value = v.positionId;
        if (v.value === this.ruleForm.positionId) {
          this.ruleForm.positionName = v.label;
        }
        if (v.children != undefined) {
          this.recursivePosition(v.children);
        }
        return v;
      });
      this.positionDescNameList = data;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitHandler();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitHandler() {
      let params = {};
      if (this.ruleForm.orderType == "Y") {
        params = {
          content: this.ruleForm.contentRecord,
          projectId: this.ruleForm.projectId,
          projectName: this.ruleForm.projectName,
          enterpriseId: this.ruleForm.enterpriseId,
          enterpriseDescName: this.ruleForm.enterpriseName,
          // positionId: this.ruleForm.positionId,
          // positionDescName: this.ruleForm.positionName,
          contactPerson: this.ruleForm.contacts,
          contactPhone: this.ruleForm.telephone,
          ordPersonName: this.ruleForm.handledPeople,
          ordPersonPhone: this.ruleForm.hotlineCusPhone,
          issueType: this.ruleForm.questionType,
          isOrderEvent: this.ruleForm.orderType,
          orderTemplateName: this.ruleForm.orderTemplateName,
          orderTemplateId:
            this.ruleForm.orderType == "Y"
              ? this.ruleForm.orderTemplateId
              : null,
        };
      } else {
        params = {
          content: this.ruleForm.contentRecord,
          projectId: this.ruleForm.projectId,
          projectName: this.ruleForm.projectName,
          enterpriseId: this.ruleForm.enterpriseId,
          enterpriseDescName: this.ruleForm.enterpriseName,
          // positionId: this.ruleForm.positionId,
          // positionDescName: this.ruleForm.positionName,
          contactPerson: this.ruleForm.contacts,
          contactPhone: this.ruleForm.telephone,
          ordPersonName: this.ruleForm.handledPeople,
          ordPersonPhone: this.ruleForm.hotlineCusPhone,
          issueType: this.ruleForm.questionType,
          isOrderEvent: this.ruleForm.orderType,
        };
      }
      addWorkOrder(params).then((res) => {
        if (res.code === 200) {
          this.$message.success("新增成功！");
          this.$emit("close", true);
        } else {
          this.$message.warning("新增失败！");
        }
      });
    },
    resetForm() {
      this.$emit("close", false);
    },

    dealFileItems() {
      this.fileList.forEach((item) => {
        item.fileName = item.displayName.replace(/(.*\/)*([^.]+).*/gi, "$2");
        item.fileFormat = item.displayName.replace(/.+\./, "");
      });
    },
  },
};
</script>

<style scoped lang="less">
.guide-form-comp {
  .temolate-list-container {
    .title-line {
      border-bottom: 1px solid #ebeef5;
      display: flex;
      & > span {
        flex: 1;
        text-align: center;
        color: #909399;
        font-weight: 500;
      }
    }
    .no-data-line {
      text-align: center;
      border-bottom: 1px solid #e8e8e8;
    }
    .detail-line {
      border-bottom: 1px solid #ebeef5;
      display: flex;
      & > span {
        flex: 1;
        text-align: center;
      }
      i {
        cursor: pointer;
      }
    }
  }
  .avatar-uploader {
    ::v-deep .el-upload {
      width: 100%;
    }
    .upload-handler-line {
      border-bottom: 1px solid #ebeef5;
      text-align: center;
      i {
        margin-right: 5px;
      }
    }
  }
}
</style>